<template>
  <v-card class="rounded-lg pt-3">
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-data-table
      :headers="headers"
      :items="sendList?.records"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :search="search"
      expand-icon="mdi-plus-circle-outline"
      expand-icon-color="red"
      icon-size="3"
      item-key="name"
      :show-expand="showExpand"
      dense
      :fixed-header="true"
      :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
      :items-per-page="this.getPageListSetting.count"
      :options.sync="options"
      :server-items-length="sendList?.totalCount ?? 20"
      :loading="this.loading"
    >
      <template v-slot:top>
        <v-toolbar flat :prominent="isXs">
          <v-row>
            <v-col cols="12" xs="12" sm="3">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="success" block v-on="on">
                    نامه جدید
                    <v-icon> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-on:click="goInternalMessage"
                      v-if="
                        $store.state.CurrentUser.permissions.includes(
                          'CreateInternalMessage'
                        )
                      "
                    >
                      نامه داخلی
                    </v-list-item>
                    <v-list-item
                      v-on:click="goArrivedMessage"
                      v-if="
                        $store.state.CurrentUser.permissions.includes(
                          'CreateImportMessage'
                        )
                      "
                    >
                      نامه وارده
                    </v-list-item>
                    <v-list-item
                      v-on:click="goIssuedMessage"
                      v-if="
                        $store.state.CurrentUser.permissions.includes(
                          'CreateExportMessage'
                        )
                      "
                    >
                      نامه صادره
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="9">
              <v-text-field
                dense
                outlined
                v-model="search"
                label="جستجو"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">More info about</td>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ toPersianDate(item.createdDate) }}</span>
      </template>
      <template v-slot:item.messageTypeString="{ item }">
        <router-link
          :to="{
            name: 'Letter',
            params: {
              create: false,
              pagetype: pageType,
              messagetype: item.messageTypeFk,
              id: item.messageId,
            },
          }"
          ><span>{{ item.messageTypeString }}</span>
        </router-link>
      </template>
      <template v-slot:item.messageTypeStringBadge="{ item }">
        <v-chip
          v-if="item.messageTypeFk == 4"
          class="message-badge"
          color="blue"
        >
          <router-link
            style="color: white"
            :to="{
              name: 'Letter',
              params: {
                create: false,
                pagetype: pageType,
                messagetype: item.messageTypeFk,
                id: item.messageId,
              },
            }"
            ><span>{{ item.messageTypeString }}</span>
          </router-link>
        </v-chip>
        <v-chip
          v-else-if="item.messageTypeFk == 5"
          class="message-badge"
          color="pink"
        >
          <router-link
            style="color: white"
            :to="{
              name: 'Letter',
              params: {
                create: false,
                pagetype: pageType,
                messagetype: item.messageTypeFk,
                id: item.messageId,
              },
            }"
            ><span>{{ item.messageTypeString }}</span>
          </router-link>
        </v-chip>
        <v-chip v-else class="message-badge" color="teal">
          <router-link
            style="color: white"
            :to="{
              name: 'Letter',
              params: {
                create: false,
                pagetype: pageType,
                messagetype: item.messageTypeFk,
                id: item.messageId,
              },
            }"
            ><span>{{ item.messageTypeString }}</span>
          </router-link>
        </v-chip>
      </template>

      <template v-slot:item.isReaded="{ item }">
        <v-icon color="error" v-if="!item.isReaded" left>mdi-email</v-icon>
        <v-icon color="success" v-else left>mdi-email-open</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="ma-1"
              color="error"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>حذف</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
            >لغو</v-btn
          >
          <v-btn color="error" variant="text" @click="deleteItemConfirm"
            >بله</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";

export default {
  mixins: [myMixin],
  props: [
    "headers",
    "sendList",
    "pageType",
    "singleExpand",
    "showExpand",
    "loading",
    "title",
  ],
  data() {
    return {
      dialogDelete: false,
      deleteMessageId: 0,
      deleteIsSend: false,
      search: "",
      expanded: [],
      options: {},
    };
  },
  computed: {
    ...mapGetters(["getUrl", "getPageListSetting"]),
    isXs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    goInternalMessage() {
      this.$router.push({
        name: "Letter",
        params: { create: true, pagetype: "AllMessage", messagetype: 4, id: 0 },
      });
    },
    goArrivedMessage() {
      this.$router.push({
        name: "Letter",
        params: { create: true, pagetype: "AllMessage", messagetype: 5, id: 0 },
      });
    },
    goIssuedMessage() {
      this.$router.push({
        name: "Letter",
        params: { create: true, pagetype: "AllMessage", messagetype: 6, id: 0 },
      });
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.deleteMessageId = item.messageId;
      this.deleteIsSend = item.isSend;
    },
    deleteItemConfirm() {
      let data = {
        Language: 0,
        Id: this.deleteMessageId,
        PageType: this.$route.params.pagetype,
        IsSend: this.deleteIsSend,
      };
      axios
        .delete(`${this.getUrl}/message/delete/`, { data: data })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.$router.go();
            this.closeDelete();
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
              timeOut: 2000,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
  watch: {
    options: {
      handler() {
        this.$emit("changed", this.options);
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
        this.$emit("search", this.search);
      },
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style scoped>
.message-badge {
  margin: 4px 16px;
  padding: 0px 32px;
  min-width: 91px;
  max-width: 91px;
}

.message-badge >>> a {
  text-decoration: auto;
}
</style>